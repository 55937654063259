import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layoutName: 'default',
    activeTitle: '',
    sDefaults: {tab: 'Prospects'},
    isLoading: true,
    cityMaster: [],
    countryCodes: [],
    reraStatusMaster: [],
    rmMaster: [],
    LocalitiesMaster: [],
    executiveMaster: [],
    paymentModes: []
  },
  mutations: {
    setLayout(state, layout){
      state.layoutName = layout
    },
    setActiveTitle(state, title){
      state.activeTitle = title
    },
    setCity(state, city){
      state.cityMaster = city
    },
    setCountryCodes(state, data) {
      state.countryCodes = data
    },
    setReraStatus(state, status){
      state.reraStatusMaster = status
    },
    setRm(state, rm){
      state.rmMaster = rm
    },
    setLocalities(state, locality){
      state.LocalitiesMaster = locality
    },
    setFieldExecutives(state, executive){
      state.executiveMaster = executive
    },
    setLoading(state, isLoading){
      state.isLoading = isLoading
    },
    setPaymentModes(state, paymentModes){
      state.paymentModes = paymentModes
    },

  },
  actions: {
    setLayout({commit}, layout){
      commit('setLayout', layout);
    },
    setActiveTitle({commit}, title){
      commit('setActiveTitle', title);
    },
    setCity({commit}, city){
      commit('setCity', city)
    },
    setCountryCodes({commit}, data){
      commit('setCountryCodes', data)
    },
    setReraStatus({commit}, status){
      commit('setReraStatus', status)
    },
    setRm({commit}, rm){
      commit('setRm', rm)
    },
    setLocalities({commit}, locality){
      commit('setLocalities', locality)
    },
    setFieldExecutives({commit}, executive){
      commit('setFieldExecutives', executive)
    },
    setLoading({commit}, isLoading){
      commit('setLoading', isLoading);
    },
    setPaymentModes({commit}, paymentModes){
      commit('setPaymentModes', paymentModes);
    },

  },
  getters: {
    layout(state){
      return state.layoutName;
    },
    sDefaults(state){
      return state.sDefaults;
    },
    activeTitle(state){
      return state.activeTitle;
    },
    cityMasterList(state){
      return state.cityMaster;
    },
    countryList(state) {
      return state.countryCodes;
    },
    reraStatusMasterList(state){
      return state.reraStatusMaster;
    },
    rmMasterList(state){
      return state.rmMaster;
    },
    LocalitiesMasterList(state){
      return state.LocalitiesMaster;
    },
    fieldExecutiveMasterList(state){
      return state.executiveMaster;
    },
    paymentModes(state){
      return state.paymentModes;
    },
    setActiveTitle({commit}, title){
      commit('setActiveTitle', title);
    },
    isLoading(state){
      return state.isLoading;
    }
  }
})
